
    /deep/.expanded .el-table_1_column_1 .cell{
        display: flex;
        align-items: center;
    }
    /deep/.el-table__row--level-1 .el-table-column--selection .cell .el-checkbox{
        display: none;
    }
    /deep/ .el-table .cell {
      position: relative;
    }
    /deep/ .el-table .el-table__expand-icon {
      position: absolute;
      left: -5px;
      top: 8px;
    }
    .createStepTwo{
        .createStepTwo-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn:nth-child(1){
                margin-left: 0;
            }
            .btn{
                width: 100px;
                height: 30px;
                line-height: 1px;
                background: #ffffff;
                border-color: #1222D8;
                color: #1222D8;
                margin-left: 20px;
            }
        }
        .header-class{
            color: #343434;
            font-size: 16px;
        }
        .addRobot{
            padding-top: 50px;
            .addRobotBtn{
                width: 126px;
                height: 30px;
                line-height: 0;
                background: #1222D8;
                border-color: #1222D8;
                color: #ffffff;
            }
        }
        .createStepBottom{
            margin-top: 100px;
            display: flex;
            justify-content: center;
            .last-step{
                width: 100px;
                background: #ffffff;
                color: #1122D8;
                border-color:#1122D8 ;
            }
            .next-step{
                width: 100px;
                background: #1122D8;
                color: #ffffff;
                border-color: #1122D8;
                margin-left: 20px;
            }
        }
    }
